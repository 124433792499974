import { Device } from '@capacitor/device';
import { useLocalStorage } from '@vueuse/core';
import { computed, ref, watchEffect } from 'vue';
import { createI18n } from 'vue-i18n';

import messages, { AvailableLocales, availableLocales } from '@/translations';

const DEFAULT_LOCALE = 'en';

function filterAvailable(value: string | null): AvailableLocales | null {
	if (value === null || !Object.keys(messages).includes(value)) {
		return null;
	}

	return value as AvailableLocales;
}

const storedLocale = useLocalStorage<string | null>('locale', null);
const deviceLocale = ref<string | null>(null);
const currentLocale = computed(
	() =>
		filterAvailable(storedLocale.value) ?? filterAvailable(deviceLocale.value) ?? DEFAULT_LOCALE
);

const i18n = createI18n({
	legacy: false,
	globalInjection: true,
	locale: currentLocale.value,
	fallbackLocale: DEFAULT_LOCALE,
	messages,
});

watchEffect(() => {
	i18n.global.locale.value = currentLocale.value;
});

Device.getLanguageCode().then((result) => (deviceLocale.value = result.value));

export default i18n;
export const locale = currentLocale;
export function setLocale(value: AvailableLocales) {
	storedLocale.value = value;
}
export { availableLocales };
