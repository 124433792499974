import { useAxios, UseAxiosOptions } from '@vueuse/integrations/useAxios';
import axios, { AxiosRequestConfig } from 'axios';
import { ref } from 'vue';

type UseApiOptions = UseAxiosOptions;

const currentToken = ref<string | null | undefined>(null);

const instance = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	headers: {
		Accept: 'application/json, multipart/form-data',
	},
	timeout: process.env.VUE_APP_TIMEOUT ?? 10000,
});

axios.interceptors.request.use(async (config) => {
	const isTokenRequired = !config.suppressToken;

	if (!currentToken.value || !isTokenRequired) {
		return config;
	}

	config.headers = {
		...config.headers,
		Authorization: `Bearer ${currentToken.value}`,
	};

	return config;
});

export function useApi() {
	function request<T = any>(url: string, config?: AxiosRequestConfig, options?: UseApiOptions) {
		if (config) {
			return useAxios<T>(url, config, instance, options);
		}

		return useAxios<T>(url, instance);
	}

	function setToken(token: string | null | undefined) {
		currentToken.value = token;
	}

	function clearToken() {
		currentToken.value = null;
	}

	return {
		request,
		api: instance,
		setToken,
		clearToken,
	};
}

declare module 'axios' {
	export interface AxiosRequestConfig {
		suppressToken?: boolean;
	}
}
