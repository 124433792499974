import de from './de.json';
import en from './en.json';
import fr from './fr.json';

export type MessageSchema = typeof en;
export const availableLocales = {
	de: 'Deutsch',
	en: 'English',
	fr: 'Français',
};
export type AvailableLocales = keyof typeof availableLocales;

const messages: Record<AvailableLocales, MessageSchema> = { de, en, fr };

export default messages;
