import { Map } from '@fawmi/vue-google-maps';
import { ref, watchEffect } from 'vue';

// eslint-disable-next-line no-undef
type GoogleMap = google.maps.Map;

type LatLng = { lat: number; lng: number };

export type UseMapInstanceOptions = {
	minZoom: number;
	maxZoom: number;
	center: LatLng;
	zoom: number;
};

export function useMapInstance({ minZoom, maxZoom, center, zoom }: UseMapInstanceOptions) {
	const mapRef = ref<typeof Map | null>(null);
	const mapInstance = ref<GoogleMap | null>(null);

	watchEffect(async () => {
		if (!mapRef.value) {
			return;
		}

		mapInstance.value = await mapRef.value.$mapPromise;
	});

	watchEffect(() => {
		if (!mapInstance.value) {
			return;
		}

		mapInstance.value.setOptions({
			minZoom,
			maxZoom,
		});
	});

	const fitBounds = (markers: Array<LatLng>) => {
		const map = mapInstance.value;

		if (!window.google || !map) {
			return;
		}

		if (!markers.length) {
			map.setCenter(center);
			map.setZoom(zoom);
		}

		const bounds = new window.google.maps.LatLngBounds();

		markers.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));

		map.fitBounds(bounds);
	};

	return {
		mapRef,
		mapInstance,
		fitBounds,
	};
}
