<template>
	<svg width="0" height="0">
		<defs>
			<filter
				id="iconShadowFilter"
				x="-33.3%"
				y="-33.3%"
				width="166.7%"
				height="166.7%"
				filterUnits="objectBoundingBox"
			>
				<feOffset dy="2" in="SourceAlpha" result="iconShadowFilter__shadowOffsetOuter1" />
				<feGaussianBlur
					stdDeviation="2"
					in="iconShadowFilter__shadowOffsetOuter1"
					result="iconShadowFilter__shadowBlurOuter1"
				/>
				<feColorMatrix
					values="
            0 0 0 0 0.6
            0 0 0 0 0.3
            0 0 0 0 0.05
            0 0 0 1 0
            "
					in="iconShadowFilter__shadowBlurOuter1"
					result="iconShadowFilter__shadowColor"
				/>

				<feMerge>
					<feMergeNode in="iconShadowFilter__shadowColor" />
					<feMergeNode in="SourceGraphic" />
				</feMerge>
			</filter>
		</defs>
	</svg>
</template>
