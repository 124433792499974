import { toastController } from '@ionic/vue';
import { tryOnScopeDispose } from '@vueuse/core';
import { computed, watchEffect } from 'vue';

export function useToast(
	message: string | (() => string),
	type: 'error' | 'success' | null = null
) {
	let color: string;
	let toast: HTMLIonToastElement;

	switch (type) {
		case 'error':
			color = 'danger';
			break;
		case 'success':
			color = 'success';
			break;
	}

	const computedMessage = computed(() => (typeof message === 'function' ? message() : message));

	const makeToast = async () => {
		if (!toast) {
			toast = await toastController.create({
				message: computedMessage.value,
				duration: 2000,
				color,
			});

			watchEffect(() => {
				toast.message = computedMessage.value;
			});
		}

		return toast;
	};

	const present = async () => (await makeToast()).present();
	const dismiss = async () => (await makeToast()).dismiss();
	const destroy = async () => toast?.dismiss();

	tryOnScopeDispose(destroy);

	return {
		present,
		dismiss,
		destroy,
	};
}
