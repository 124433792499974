import { flatten } from 'lodash';
import { defineStore } from 'pinia';

import { HostResponse, HostsResponse, MarkersResponse } from '@/api/types';
import { useApi } from '@/composables/useApi';

export type HostsMarker = {
	id: number;
	lat: number;
	lng: number;
	title: string;
	icon_id: number;
	icon_url: string;
};

export type Host = HostResponse;
export type Hosts = Host[];

export type HostsPaged = {
	maxPage: number;
	totalPages: number;
	pages: Hosts[];
};

export type HostsState = {
	hosts: HostsPaged;
	markers: HostsMarker[];
	isLoaded: boolean;
};

export const useHosts = defineStore('hosts', {
	state: (): HostsState => ({
		hosts: {
			pages: [],
			maxPage: 0,
			totalPages: 1,
		},
		markers: [],
		isLoaded: false,
	}),

	getters: {
		getMarkers: ({ markers }) => markers,
		hasMorePages: ({ hosts }) => hosts.maxPage < hosts.totalPages,
		hostsCombined: ({ hosts }) => flatten(hosts.pages).filter((item) => !!item),
	},

	actions: {
		async loadMarkers() {
			const { api } = useApi();

			const response = await api.get<MarkersResponse>(
				'/geodir/v2/markers?post_type=gd_place'
			);

			this.markers = response.data.items.map(
				// eslint-disable-next-line @typescript-eslint/naming-convention
				({ m: id, lt: lat, ln: lng, t: title, i: icon_id }) => ({
					id: +id,
					lat: +lat,
					lng: +lng,
					title,
					icon_id: +icon_id,
					icon_url: `${response.data.baseurl}/${response.data.icons[icon_id].i}`,
				})
			);
		},

		resetHosts() {
			this.hosts = {
				pages: [],
				maxPage: 0,
				totalPages: 1,
			};
		},

		async loadHostsPage(page: number, perPage = 20) {
			const { api } = useApi();

			const response = await api.get<HostsResponse>(
				`/geodir/v2/hosts?page=${page}&per_page=${perPage}`
			);

			this.hosts.totalPages = +response.headers['x-wp-totalpages'];
			this.hosts.pages[page - 1] = response.data;
		},

		async loadMoreHosts() {
			const page = this.hosts.maxPage + 1;

			if (page > this.hosts.totalPages) {
				return;
			}

			await this.loadHostsPage(page);

			this.hosts.maxPage = Math.max(page, this.hosts.maxPage);
		},
	},
});
