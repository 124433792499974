import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import HomePage from '@/views/HomePage.vue';
import LoginPage from '@/views/LoginPage.vue';
import MenuPage from '@/views/MenuPage.vue';
import TabsPage from '@/views/TabsPage.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'login',
		component: LoginPage,
		meta: {
			onlyGuest: true,
		},
	},
	{
		path: '/tabs/',
		component: TabsPage,
		children: [
			{
				path: '',
				redirect: '/tabs/home',
			},
			{
				path: 'home',
				name: 'home',
				component: HomePage,
				meta: {
					onlyAuth: true,
				},
			},
			{
				path: 'menu',
				name: 'menu',
				component: MenuPage,
				meta: {
					onlyAuth: true,
				},
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
