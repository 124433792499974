/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
/* Theme variables */
import './theme/variables.css';

import VueGoogleMaps from '@fawmi/vue-google-maps';
import { IonicVue } from '@ionic/vue';
import { createApp } from 'vue';
import InlineSvg from 'vue-inline-svg';

import App from './App.vue';
import i18n from './i18n';
import pinia from './pinia';
import router from './router';

const app = createApp(App);
app.use(IonicVue);
app.use(router);
app.use(pinia);
app.use(i18n);
app.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
	},
	installComponents: false,
});

app.component('InlineSvg', InlineSvg);

router.isReady().then(() => {
	app.mount('#app');
});
