import { useLocalStorage } from '@vueuse/core';
import { defineStore } from 'pinia';

import { LoginResponse } from '@/api/types';
import { useApi } from '@/composables/useApi';

export type AuthState = {
	token: string | null;
	userEmail: string | null;
	userDisplayName: string | null;
	userNicename: string | null;
	isValidated: boolean;
};

const storedState = useLocalStorage<
	Pick<AuthState, 'token' | 'userEmail' | 'userDisplayName' | 'userNicename'>
>('auth', {
	token: null,
	userEmail: null,
	userDisplayName: null,
	userNicename: null,
});

console.log(storedState.value);

export const useAuth = defineStore('auth', {
	state: (): AuthState => ({
		...storedState.value,
		isValidated: false,
	}),
	getters: {
		isAuthorized: (state) => !!state.token,
	},
	actions: {
		async login(username: string, password: string, remember = false) {
			const { api } = useApi();

			const response = await api.post<LoginResponse>(
				'/jwt-auth/v1/token',
				{
					username,
					password,
				},
				{
					suppressToken: true,
				}
			);

			this.token = response.data.token;
			this.userEmail = response.data.user_email;
			this.userDisplayName = response.data.user_display_name;
			this.userNicename = response.data.user_nicename;

			if (remember) {
				storedState.value = { ...this.$state };
			}

			return response;
		},

		async logout() {
			storedState.value = {
				token: null,
				userEmail: null,
				userDisplayName: null,
				userNicename: null,
			};
			this.$reset();
		},

		async validate() {
			const { api } = useApi();

			if (!this.token) {
				return false;
			}

			try {
				await api.post('/jwt-auth/v1/token/validate');
				this.isValidated = true;
				return true;
			} catch (e) {
				this.$reset();
				return false;
			}
		},
	},
});
