import type { PiniaPluginContext } from 'pinia';
import { markRaw, Ref, ref } from 'vue';

export function PiniaLoading({ options, store }: PiniaPluginContext) {
	if (options.actions) {
		const $loading: Record<string, Ref<boolean>> = {};
		Object.keys(options.actions).forEach((actionKey) => {
			$loading[actionKey] = ref(false);
		});

		store.$onAction(({ name, after, onError }) => {
			$loading[name].value = true;

			after(() => {
				$loading[name].value = false;
			});
			onError(() => {
				$loading[name].value = false;
			});
		});

		store.$loading = markRaw($loading);
	}
}

declare module 'pinia' {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	export interface PiniaCustomProperties<Id, S, G, A> {
		$loading: {
			[K in keyof A as A[K] extends (...args: any) => Promise<any> ? K : never]: Ref<boolean>;
		};
	}
}
